<template>
    <div class="modal" :class="{'is-active': $parent.show_bulk_assign_modal}">
        <div class="modal-background" @click.self="$parent.toggle_bulk_modal"></div>
        <div class="modal-card">
            <div class="modal-card-head">
                <div class="modal-card-title">
                     <p class="is-size-6 has-text-centered is-title has-text-weight-bold" >
                        Assign multiple materials to warehouse
                    </p>
                </div>
            </div>
            <div class="modal-card-body">
                <table class="table is-fullwidth is-narrow  mb-0">
                    <thead>
                        <tr>
                            <td>Material</td>
                            <td width="40%">Dates</td>
                            <td >Amount</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(ch, index) in $parent.checked_dates_amount" :key="index" class="has-background-info has-text-white">
                            <td>
								<small class="has-text-white-ter">
									{{ch.date.material_Category}}
								</small><br>
								{{ch.date.material_Caption}}
                            </td>
                            <td>
                                <p class=""><small>{{ch.date.dates[0] | customdate2}} - {{ch.date.dates[1] | customdate2}}</small></p> 
                            </td>
                            <td>
                                <div class="control" style="" :class="{'material-assigned-warehouse': ch.date.warehouse_job != null}"> 
                                    <input class="input is-small" style="width:80px;" disabled="disabled"
                                        v-model="ch.date.Qty" type="number">
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p class="has-text-weight-semibold mt-2">Select warehouse </p>
                <div  class="select is-fullwidth">
                    <select v-model="bulk_warehouse" name="" id="">
                        <option value="0">Select warehouse</option>
                        <option v-for="(w, index) in $parent.$parent.warehouses" :key="index" :value="w.Id">{{w.Caption}}</option>
                    </select>
                </div>
                <hr class="my-3">
                <a @click.prevent="bulk_assign_materials()" href="" class="button is-success is-fullwidth mt-2">
                    Assign
                </a>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
export default {
    data() {
        return {
            bulk_warehouse: 0
        }
    },
    methods: {
        bulk_assign_materials(){
            // this.$parent.checked_dates_amount
            // this.bulk_warehouse

            // axios.post('', {})
			let w = this.$parent.$parent.warehouses.filter(r => r.Id == this.bulk_warehouse)

            this.$parent.currentWarehouse = w.length > 0 ? w[0] : {} //this.curr_warehouse
			
			this.$parent.checked_dates_amount.map((d, i) => 
			{
				setTimeout( () => {
					this.$parent.currentMaterial = {...d.material}
					this.$parent.currentDates = {...d.date}
					this.$parent.addJobMaterial()
				}, 500 * i)
			})
        }
    },

}
</script>

<style>

</style>
